.dot-slider {
    margin: 0 auto;
}

.slider-content {
    text-align: center;
    margin-bottom: 20px;
}

.dot-indicators {
    display: flex;
    justify-content: center;
}

.dot-indicators span {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: gray;
    margin: 0 5px;
    cursor: pointer;
}

.dot-indicators span.active {
    background-color: black;
}